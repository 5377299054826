<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import { isInViewport } from "@/Modules/Dom";
import "animate.css";
import { loadLanguageAsync, getActiveLanguage, trans } from "laravel-vue-i18n";

const animate = () => {
    if (isInViewport(document.querySelector("#heroImg"))) {
        document
            .querySelector("#heroImg")
            .classList.add("animate__animated", "animate__zoomIn");
    }
    if (isInViewport(document.querySelector("#content"))) {
        document
            .querySelector("#content")
            .classList.add("animate__animated", "animate__fadeIn");
    }
};

const Scroll = () => {
    animate();
};

onMounted(() => {
    window.addEventListener("scroll", Scroll);
    animate();
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", Scroll);
});
</script>
<template>
    <section id="hero" class="hero" :class="{
        heroEnglish: getActiveLanguage() == 'en',
    }">
        <div class="container position-relative">
            <div class="row gy-5 justify-content-center" data-aos="fade-in">
                <div id="content"
                    class="order-2 m-0 text-center col-lg-6 order-lg-1 d-flex flex-column justify-content-center text-lg-start">
                    <h2 class="text-center w-100 text-capitalize">
                        {{ $t("home.hero.title") }}
                        <span> {{ $t("home.hero.woodMaker") }} </span>
                    </h2>
                    <p class="text-center text-capitalize">
                        {{ $t("home.hero.desc") }}
                    </p>

                </div>
                <div class="order-1 col-7 col-lg-6 order-lg-2" id="heroImg">
                    <v-img src="/images/Pages/Home/Hero/heroimag2.png" lazy-src="/images/Pages/Home/Hero/heroimag2.png"
                        aspect-ratio="1" class="img-fluid" alt="Triumph hotal" title="wood maker">
                        <template v-slot:placeholder>
                            <div>
                                <v-progress-circular indeterminate></v-progress-circular>
                            </div>
                        </template>
                    </v-img>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.hero {
    width: 100%;
    background-image: url(/images/Pages/Home/Hero/Untitled.svg);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
}

.heroEnglish {
    background-image: url(/images/Pages/Home/Hero/heroBgEng.svg);
    background-position: inherit;
}



.hero h2 {
    font-weight: 700;
    margin-bottom: 20px;
    color: $secondary;
}

.hero p {
    color: $secondary;
    font-weight: 400;
    margin-bottom: 30px;
}

.hero .btn-get-started {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 14px 40px;
    border-radius: 50px;
    transition: 0.3s;
    color: $primary;
    background: rgb(255 255 255);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
    border: 2px solid $primary;
}

.hero .btn-get-started:hover {
    border-color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 640px) {
    .hero h2 {
        font-size: 36px;
    }

    .hero {
        height: 500px;
    }

    .hero .btn-get-started,
    .hero .btn-watch-video {
        font-size: 14px;
    }
}

.hero .icon-boxes {
    padding-bottom: 60px;
}

@media (min-width: 1200px) {
    .hero .icon-boxes:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(50% + 20px);
        background-color: #fff;
    }
}

.hero .icon-box {
    padding: 60px 30px;
    position: relative;
    overflow: hidden;
    background: #008d7d;
    box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    height: 100%;
    width: 100%;
    text-align: center;
}

.hero .icon-box .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.hero .icon-box .title a {
    color: #fff;
    transition: 0.3s;
}

.hero .icon-box .icon {
    margin-bottom: 20px;
    padding-top: 10px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 48px;
    line-height: 1;
    color: rgba(255, 255, 255, 0.6);
}

.hero .icon-box:hover {
    background: #009786;
}

.hero .icon-box:hover .title a,
.hero .icon-box:hover .icon {
    color: #fff;
}

@include breakpoint() {
    .hero h2 {
        font-size: $fs-md;

        span {
            color: $secondary;
        }
    }
}

@include breakpoint(lg) {
    .hero h2 {
        font-size: $fs-lg;

        span {
            color: $primary;
        }
    }
}
</style>
